import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Spin, Button, Row, Col, message, Dropdown, Menu, Tooltip as Tip, Condition, Empty } from '../../components';
import VectorMap, { Layer, Tooltip, Legend, Source, Title, Font, ControlBar, Label } from 'devextreme-react/vector-map';
import * as Sentry from '@sentry/browser';
import { getMapData } from '../../services';
import { t } from 'i18next';
import { LeftOutlined, DownloadOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import {
  GridFixedSpan,
  ID_DISTRICT,
  ID_STATE,
  ALL_DOMAINS,
  STATE_DOMAIN,
  GEO_DATA_DOMAIN,
  TYPE_ALL_DIMENSIONS,
} from '../../constants';
import { LOCATION_GRANALURITY } from '../../constants';
import { cleanName, legendTitle } from '../../utils/common';
import { toShortCurrency, voidFunction } from '../../utils/common';
import GraphCard from '../Plotly/GraphCard';
import { useAppliedFilterContext } from '../../providers/FilterContextProvider';
import { usePrevious } from '../Hooks/use-previous';

const getBounds = (data) => {
  return [
    Number.parseFloat(get(data, 'boundTop', 0)),
    Number.parseFloat(get(data, 'boundLeft', 0)),
    Number.parseFloat(get(data, 'boundBottom', 0)),
    Number.parseFloat(get(data, 'boundRight', 0)),
  ];
};

export const DowloadMapButton = ({ handleMenuClick = voidFunction }) => {
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="JPEG" id="download-graph">
        {t('JPEG')}
      </Menu.Item>
      <Menu.Item key="PNG">{t('PNG')}</Menu.Item>
      <Menu.Item key="SVG">{t('SVG')}</Menu.Item>
      <Menu.Item key="PDF">{t('PDF')}</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={'click'}>
      <Button
        type="link"
        size="small"
        icon={
          <Tip title="Download">
            <DownloadOutlined />
          </Tip>
        }
      ></Button>
    </Dropdown>
  );
};

export const DownloadHiddenButton = ({ handleMenuClick = voidFunction }) => (
  <Button className="d-none" id="download-graph" onClick={() => handleMenuClick({ key: 'PNG' })}></Button>
);

export const DEFAULT_COLOR_PALLET = {
  Default: [
    '#636EFA',
    '#EF553B',
    '#00CC96',
    '#AB63FA',
    '#FFA15A',
    '#19D3F3',
    '#FF6692',
    '#B6E880',
    '#FF97FF',
    '#FECB52',
  ],
};

const MapOnVisualize = ({
  defaultMap,
  datasetID = '',
  sourceID = '',
  sourceData = {},
  colorPallet = DEFAULT_COLOR_PALLET,
  selectedPallet = 'Default',
  setLoadMap = voidFunction,
  setMapFilter = voidFunction,
  loadingData = false,
  loadingYears,
  years,
  onChangeYear,
  aggregateOverTime = false,
  onChangeAggregateOverTime,
  charTitle = '',
  selectedYear,
  selectMode,
  merge = false,
  domain,
}) => {
  const { getSelectedFilters } = useAppliedFilterContext();
  const [mapData, setMapData] = useState([]);
  const [centerCoordinates, setCenterCoordinates] = useState([]);
  const [mapType, setMapType] = useState(defaultMap);
  const [isLoading, setLoading] = useState(false);
  const [currentGranularity, setCurrentGranularity] = useState(LOCATION_GRANALURITY.state);
  const [layers, setLayers] = useState({});
  const [mapRecords, setMapRecords] = useState([]);
  const [heatMap, setHeatMap] = useState({});
  const [bubleMap, setBubbleMap] = useState({});
  const [region, setRegion] = useState({});
  const [dimensionPaths, setDimensionPaths] = useState([]);
  const [showMapControls, setShowMapControls] = useState(true);
  const mapReferrence = useRef(null);

  const changeLocation = useMemo(() => {
    const selected = getSelectedFilters({ sourceID, TYPE_ALL_DIMENSIONS }) || [];
    if (selected?.length) return selected?.filter((item) => item.filterType === TYPE_ALL_DIMENSIONS)[0]?.data;
    else return [];

    // eslint-disable-next-line
  }, [getSelectedFilters]);

  const prevChangeLocation = usePrevious(changeLocation);

  useEffect(() => {
    const updateStrokeWidth = () => {
      const tspans = document.querySelectorAll('tspan[stroke-width="2"]');
      tspans.forEach((tspan) => {
        tspan.setAttribute("stroke-width", "0");
      });
    };

    // Call initially to handle already rendered elements
    updateStrokeWidth();

    // Observe DOM mutations to handle dynamically added elements
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList" || mutation.type === "attributes") {
          updateStrokeWidth();
        }
      });
    });

    // Start observing the document for changes
    observer.observe(document, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ["stroke-width"],
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  

  useEffect(() => {
    if (!isEqual(prevChangeLocation, changeLocation)) handleGoBack();

    // eslint-disable-next-line
  }, [changeLocation]);

  useEffect(() => {
    if (
      !isEqual(sourceData.data || [], mapRecords) ||
      !isEqual(region, sourceData.region?.[0] || {}) ||
      !isEqual(get(sourceData, 'heatMap[0]', {}), heatMap) ||
      !isEqual(get(sourceData, 'bubble[0]', {}), bubleMap)
    ) {
      if (!sourceData?.data?.length) {
        setMapRecords([]);
        setHeatMap({});
        setBubbleMap({});
        setRegion({});
        setLayers({});
        setDimensionPaths([]);
      } else {
        let regionData = get(sourceData, 'region[0]', {});

        if (region?.ID === ID_STATE && currentGranularity === LOCATION_GRANALURITY.district) {
          setCurrentGranularity(LOCATION_GRANALURITY.state);
          setMapType(defaultMap);
        }

        setHeatMap(get(sourceData, 'heatMap[0]', {}));
        setBubbleMap(get(sourceData, 'bubble[0]', {}));
        setRegion(regionData);
        setMapRecords(sourceData.data);
        setDimensionPaths(get(sourceData, 'dimensionPaths', []));
      }
    }

    // eslint-disable-next-line
  }, [sourceData, mapRecords, defaultMap]);

  useEffect(() => {
    fetch(mapType);
    // eslint-disable-next-line
  }, [mapType]);

  const fetch = async (mapType) => {
    try {
      setMapData({});
      setCenterCoordinates({});
      setLayers({});
      setLoading(true);
      const { data } = await getMapData({ dataMap: mapType });

      let cCoordinates = {};
      const mapDataByDomain = transformDataByDomain(data);
      const calculateCentroid = (coordinates) => {
        let flatCoordinaters = [];
        let flattern = (items) => {
          if (typeof items[0] === 'number') {
            flatCoordinaters.push(items);
          } else if (items.length) {
            items.forEach(flattern);
          }
        };

        flattern(coordinates[coordinates.length - 1]);

        let n = flatCoordinaters.length;
        let area = 0;
        let cx = 0;
        let cy = 0;

        for (let i = 0; i < n - 1; i++) {
          let xi = flatCoordinaters[i][0];
          let yi = flatCoordinaters[i][1];
          let xi1 = flatCoordinaters[i + 1][0];
          let yi1 = flatCoordinaters[i + 1][1];

          let term = xi * yi1 - xi1 * yi;
          area += term;
          cx += (xi + xi1) * term;
          cy += (yi + yi1) * term;
        }

        area /= 2;
        cx /= 6 * area;
        cy /= 6 * area;

        return [cx, cy];
      };

      mapDataByDomain?.features.forEach((item, index) => {
        if (!item.properties?.name) {
          item.properties['name'] =
            item.properties.BlockName ||
            item.properties.DISTRICT ||
            item.properties.District ||
            item.properties.STATE_NAME ||
            item.properties.State_Name;

          mapDataByDomain.features[index] = item;
        }

        cCoordinates[item.properties.id] = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: item?.properties?.INSIDE_X
              ? [item.properties.INSIDE_X, item.properties.INSIDE_Y]
              : calculateCentroid(item.geometry.coordinates),
          },
          properties: item.properties,
        };
      });

      setMapData(mapDataByDomain);
      setCenterCoordinates(cCoordinates);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      Sentry.captureException(`Failed to fetch static data maps, ` + e);
    }
  };

  useEffect(() => {
    setMapType(defaultMap);
    setLayers({});
    setLoading(false);
  }, [datasetID, defaultMap]);

  useEffect(() => {
    if (mapRecords?.length && (heatMap?.ID || bubleMap?.ID)) {
      let heatMapData = {},
        bubbleData = {},
        heatMapColors = [],
        bubbleColors = [],
        Codes = {};

      const addToMapData = (item, dimension, md, value) => {
        let key = item[dimension.ID];

        if (!isNaN(key)) {
          md[key] = value;
          Codes[key] = { [dimension.ID]: key };
        }
      };

      const regionCode = Object.keys(mapRecords?.[0]).find((key) => key.toLowerCase().includes('code'));

      mapRecords.forEach((item) => {
        if (heatMap.ID) {
          let hmData = item[heatMap.ID] === undefined ? 0 : item[heatMap.ID];
          if (dimensionPaths.length) {
            dimensionPaths.forEach((dimension) => {
              addToMapData(item, dimension, heatMapData, hmData);
            });
          } else if (item[heatMap.ID]) {
            heatMapData[item[regionCode]] = hmData;

            if (hmData) heatMapColors.push(hmData);
          }
        }

        if (bubleMap.ID) {
          let bData = item[bubleMap.ID] || 0;
          if (dimensionPaths.length) {
            dimensionPaths.forEach((dimension) => {
              addToMapData(item, dimension, bubbleData, bData);
            });
          } else if (item[regionCode]) {
            bubbleData[item[regionCode]] = bData;

            if (bData) bubbleColors.push(bData);
          }
        }
      });

      if (!isEmpty(sourceData)) heatMapColors = sourceData.bucket[heatMap.ID];
      if (heatMapColors?.length) heatMapColors[heatMapColors.length - 1] += 1;

      if (!isEmpty(sourceData)) bubbleColors = sourceData.bucket[bubleMap.ID];
      if (bubbleColors?.length) bubbleColors[bubbleColors.length - 1] += 1;

      const colors = [...colorPallet[selectedPallet]].splice(0, 5);

      setLayers({
        heatMapData,
        bubbleData,
        heatMapColors,
        heatMapColorPallete: colors,
        bubbleColors,
        bubbleColorPallete: [...colorPallet[selectedPallet]].splice(5, 1),
        Codes,
      });
    }
    // eslint-disable-next-line
  }, [mapRecords, selectedPallet, mapData]);

  const transformDataByDomain = (data) => {
    if (domain === ALL_DOMAINS[domain] && mapType !== STATE_DOMAIN[domain]) {
      let filterData = data?.features?.filter((item) => item?.properties?.name === STATE_DOMAIN[domain]);
      if (filterData?.length) {
        return {
          ...GEO_DATA_DOMAIN[domain],
          features: filterData,
        };
      } else return data;
    } else return data;
  };

  const handleGoBack = () => {
    setMapFilter({});
    setCurrentGranularity(LOCATION_GRANALURITY.state);
    setLoadMap(ID_STATE);
    setMapType(defaultMap);
  };

  const canDrillDown = (to = 'district') => {
    const hasAccess = new RegExp(to, 'gi').test(region.ID);
    return hasAccess;
  };

  const handleClick = (element) => {
    if (domain !== ALL_DOMAINS.ndap) {
      message.warning(t('MapDrillDownError', { location: `$t(${region.ID})` }));
      return null;
    }

    let state = element.target?.attribute('name') || element.target?.attribute('text');
    if (state) {
      if (canDrillDown()) {
        if (currentGranularity === LOCATION_GRANALURITY.district || domain !== ALL_DOMAINS.ndap) {
          message.warning(t('MapDrillDownError', { location: `$t(${LOCATION_GRANALURITY.district})` }));
        } else {
          if (state) {
            let code = layers?.Codes?.[element.target?.attribute('id')];
            setMapFilter(code || {});
            setCurrentGranularity(LOCATION_GRANALURITY.district);
            setLoadMap(ID_DISTRICT);
            setMapType(state);
          }
        }
      } else {
        if (currentGranularity !== LOCATION_GRANALURITY.state) {
          setMapFilter({});
          setCurrentGranularity(LOCATION_GRANALURITY.state);
          setLoadMap(ID_STATE);
          setMapFilter({});
        }

        message.warning(t('MapDrillDownError', { location: `$t(${LOCATION_GRANALURITY.state})` }));
      }
    }
  };

  const customizeLayer = (elements, attribute, sourceLayer) => {
    elements.forEach((element) => {
      const data = Math.round(Number(layers[sourceLayer]?.[element.attribute('id')] || -1));
      element.attribute(attribute, data);
    });
  };

  const getMarkers = useCallback(
    (sourceType) => {
      const source = layers[sourceType];

      if (!source || !sourceType) {
        return [];
      }

      let markers = {
        type: 'FeatureCollection',
        features: [],
      };

      Object.keys(source).forEach((soureKey) => {
        if (centerCoordinates[soureKey]) {
          const pointer = centerCoordinates[soureKey];
          pointer.properties['value'] = source[soureKey] || 0;
          pointer.properties['text'] = pointer.properties.name;
          pointer.properties['title'] = pointer.properties.name;

          markers.features.push(pointer);
        }
      });

      return markers;
    },
    [layers, centerCoordinates]
  );

  const markers = getMarkers('bubbleData');
  const showRow = mapType !== defaultMap;
  const [showTitle, setShowTitle] = useState(false);

  const bounds = useMemo(() => getBounds(mapData), [mapData]);

  return (
    <GraphCard
      onChangeAggregateOverTime={onChangeAggregateOverTime}
      aggregateOverTime={aggregateOverTime}
      loadingYears={loadingYears}
      years={years}
      onChangeYear={onChangeYear}
      selectedYear={selectedYear}
      selectMode={selectMode}
      merge={merge}
      action={[
        <DownloadHiddenButton
          key={'Mapdownload'}
          handleMenuClick={(file) => {
            setShowTitle(true);
            setShowMapControls(false);
            setTimeout(() => {
              mapReferrence.current.instance.exportTo(cleanName(charTitle, '-'), file.key?.toLowerCase());
              setShowMapControls(true);
              setShowTitle(false);
            }, 500);
          }}
        />,
      ]}
    >
      <Spin spinning={isLoading && !loadingData}>
        <Condition show={!Boolean(sourceData?.data?.length)}>
          <Row justify="center">
            <Empty
              imageStyle={{
                width: 300,
              }}
              description={t('NoData')}
            />
          </Row>
        </Condition>
        <Condition show={Boolean(sourceData?.data?.length)}>
          <Condition show={Boolean(showRow)}>
            <Row className="mt-n2 mb-3 text-left">
              <Col {...GridFixedSpan.size.two}>
                {mapType === defaultMap ? null : (
                  <Button
                    size="small"
                    type="link"
                    onClick={handleGoBack}
                    className="pl-0 pt-0 font-12"
                    disabled={mapType === defaultMap}
                  >
                    <LeftOutlined />
                    {t('GoBack')}
                  </Button>
                )}
              </Col>
            </Row>
          </Condition>

          <div className="position-relative" style={{ minHeight: 400 }}>
            <Condition show={!isLoading}>
              <VectorMap
                style={{ height: '100vh' }}
                bounds={bounds}
                onClick={handleClick}
                background={{ borderColor: 'white' }}
                ref={mapReferrence}
              >
                <Layer
                  dataSource={{ ...mapData }}
                  name="areas"
                  palette={layers.heatMapColorPallete?.length ? layers.heatMapColorPallete : undefined}
                  paletteSize={5}
                  colorGroups={layers.heatMapColors?.length ? layers.heatMapColors : []}
                  colorGroupingField={'heatmap'}
                  customize={(e) => customizeLayer(e, 'heatmap', 'heatMapData')}
                >
                  <Label enabled={true} dataField="name" font={{ size: 12 }}></Label>
                </Layer>
                <Legend
                  horizontalAlignment="left"
                  verticalAlignment="bottom"
                  orientation="vertical"
                  customizeText={(arg) => `${toShortCurrency(arg.start)} to ${toShortCurrency(arg.end)}`}
                >
                  <Title text={legendTitle(heatMap.Description)} margin={{ top: 10 }}>
                    <Font size="12" weight="700" />
                  </Title>
                  <Font size="10" />
                  <Source layer="areas" grouping="color"></Source>
                </Legend>
                <Layer
                  dataSource={{ ...markers }}
                  name="bubbles"
                  elementType="bubble"
                  palette={layers.bubbleColorPallete?.length ? layers.bubbleColorPallete : undefined}
                  sizeGroups={layers.bubbleColors?.length ? layers.bubbleColors : []}
                  colorGroupingField={'bubble'}
                  dataField={'value'}
                  minSize={10}
                  maxSize={40}
                  opacity={0.6}
                  borderColor={'white'}
                  borderWidth={1}
                ></Layer>
                <Legend
                  markerShape={'circle'}
                  orientation="vertical"
                  horizontalAlignment="right"
                  verticalAlignment="bottom"
                  customizeText={(arg) => `${toShortCurrency(arg.start)} to ${toShortCurrency(arg.end)}`}
                >
                  <Title text={legendTitle(bubleMap.Description)} margin={{ top: 10 }}>
                    <Font size="12" weight="700" />
                  </Title>
                  <Font size="10" />
                  <Source layer="bubbles" grouping="size"></Source>
                </Legend>
                <Tooltip
                  enabled={true}
                  paddingLeftRight={0}
                  paddingTopBottom={0}
                  contentRender={(e) => {
                    const isArea = ['area', 'areas'].includes(e.layer.type);
                    const name = e.attribute('name');
                    const id = e.attribute('id');

                    let targetData = (isArea ? layers.heatMapData : layers.bubbleData) || {};
                    let targetFilter = (isArea ? heatMap : bubleMap) || {};
                    const dataValue = targetData[id] || 0;

                    return (
                      <div>
                        <div className="p-2">{name || 'Unknow area'}</div>
                        <div className="pt-2 border-top"></div>
                        <div className="pl-2 pr-2 pb-2">
                          {targetFilter?.column?.display ? (
                            <>
                              <span className="mr-2">{targetFilter.Description}</span>
                              <span>:</span>
                            </>
                          ) : null}
                          <b className="ml-2">
                            {dataValue ? Number(Number(dataValue).toFixed(2)).toLocaleString() : t('NoData')}
                          </b>
                        </div>
                      </div>
                    );
                  }}
                ></Tooltip>
                {showTitle ? <Title text={charTitle} font={{ size: 12 }} /> : null}
                <ControlBar enabled={showMapControls} horizontalAlignment={'left'} color="#F6F6F6" />
              </VectorMap>
            </Condition>
          </div>
        </Condition>
      </Spin>
    </GraphCard>
  );
};

export default MapOnVisualize;
