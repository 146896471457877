import { ReactComponent as IconAgriCuluture } from '../../assets/svg/ic-agriculture-active.svg';
import { ReactComponent as IconDefault } from '../../assets/svg/iconDefault.svg';
import { ReactComponent as IconFinance } from '../../assets/svg/ic-finance-active.svg';
import { ReactComponent as IconHealth } from '../../assets/svg/ic-health-active.svg';
import { ReactComponent as IconIndustries } from '../../assets/svg/ic-industries-active.svg';
import { ReactComponent as IconPower } from '../../assets/svg/ic-power-active.svg';
import { ReactComponent as IconScience } from '../../assets/svg/ic-science-active.svg';
import { ReactComponent as InternalExternalAffairs } from '../../assets/svg/ic-internalexternal-active.svg';
import { ReactComponent as SocioEconomic } from '../../assets/svg/ic-economy-active.svg';
import { ReactComponent as WaterResources } from '../../assets/svg/waterResources.svg';
import { ReactComponent as IconEducation } from '../../assets/svg/iconsBarChart.svg';
import { ReactComponent as IconHousing } from '../../assets/svg/ic-housing-active.svg';
import { ReactComponent as IconConsumerAffairs } from '../../assets/svg/ic-consumer-active.svg';
import { ReactComponent as IconCommunication } from '../../assets/svg/ic-communication-active.svg';
import { ReactComponent as IconCultureTourism } from '../../assets/svg/ic-tourism-active.svg';
import { ReactComponent as IconTransport } from '../../assets/svg/ic-transport-active.svg';
import { ReactComponent as IconHumanResource } from '../../assets/svg/ic-human-active.svg';





export const sectorList = [
  {
    title: 'Agriculture',
    iconType: 'agriculture',
    iconName: IconAgriCuluture,
  },
  {
    title: 'Education',
    iconType: 'education',
    iconName: IconEducation,
  },
  {
    title: 'Finance',
    iconType: 'finance',
    iconName: IconFinance,
  },
  {
    title: 'Health',
    iconType: 'health',
    iconName: IconHealth,
  },
  {
    title: 'Industries',
    iconType: 'industries',
    iconName: IconIndustries,
  },
  {
    title: 'Internal & External Affairs',
    iconType: 'commerce',
    iconName: InternalExternalAffairs,
  },
  {
    title: 'Power & Natural Resources',
    iconType: 'power',
    iconName: IconPower,
  },
  {
    title: 'Human Resources Development',
    iconType: 'humanresource',
    iconName: IconHumanResource,
  },
  {
    title: 'Science and Technology',
    iconType: 'Science',
    iconName: IconScience,
  },
  {
    title: 'Socio-Economic development',
    iconType: 'economy',
    iconName: SocioEconomic,
  },
  {
    title: 'Water Resources Management',
    iconType: 'water',
    iconName: WaterResources,
  },
  {
    title: 'Transport',
    iconType: 'transport',
    iconName: IconTransport,
  },
  {
    title: 'Housing',
    iconType: 'housing',
    iconName: IconHousing,
  },
  {
    title: 'Consumer Affairs',
    iconType: 'consumer',
    iconName: IconConsumerAffairs,
  },
  {
    title: 'Culture and Tourism',
    iconType: 'culture',
    iconName: IconCultureTourism,
  },
  {
    title: 'Communications',
    iconType: 'communications',
    iconName: IconCommunication,
  },
  {
    title: 'Default',
    iconType: 'default',
    iconName: IconDefault,
  },
];
